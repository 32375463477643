import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  sidebarSelector,
  setSidebarShow,
  setSidebarUnfoldable,
} from '../features/sidebarSlice';

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { AppSidebarNav } from './AppSidebarNav';

import { logo } from '../assets/brand/logo';
import { logoCore } from '../assets/brand/logoCore';
// import { sygnet } from 'src/assets/brand/sygnet';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// sidebar nav config
import navigation from '../_nav';

const AppSidebar = () => {
  const dispatch = useDispatch();
  const { sidebarShow, sidebarUnfoldable } = useSelector(sidebarSelector);

  /*
  const unfoldable = useSelector(state => state.sidebarUnfoldable);
  const sidebarShow = useSelector(state => state.sidebarShow);
    */

  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={visible => {
        dispatch(setSidebarShow(visible));
      }}>
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CIcon className="sidebar-brand-full" icon={logo} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={logoCore} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setSidebarUnfoldable(!sidebarUnfoldable))}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
